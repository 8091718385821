.forgot-password-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  iframe {
    border: 0;
  }

  @media screen and (max-width: 991.98px) {
    .sign-in-form {
      &__fields {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 767.98px) {
    .sign-in-form {
      &__fields {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    & {
      .title {
        text-align: center;
        margin: 20px 0;
        padding: 0 20px;
      }

      .sign-in-form {
        &__fields {
          padding: 0 20px;
          width: 100%;
        }
      }
    }
  }
}
