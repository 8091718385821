.sign-up-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .modal-header {
    padding-bottom: 20px;
  }

  &__container {
    min-height: calc(100% - 30px);
  }

  &__iframe {
    position: absolute;
    border: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    &--hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    font-size: 0;
    display: flex;
    flex: 1;
  }

  &__absolute-section {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 100%;
    overflow: auto;
    z-index: 2;
  }

  &__absolute-section-content {
    width: 100%;
    padding: 0 60px 60px 60px;
    font-size: 16px;

    & > * {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 991.98px) {
    .sign-in-form {
      &__fields {
        width: 80%;
      }
    }
    &__container {
      min-height: calc(100% - 30px);
    }
    &__iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;

      &--hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 767.98px) {
    &__container {
      min-height: calc(100% - 20px);
    }

    & {
      .modal-content {
        margin: 15px;
        width: calc(100% - 30px);
        height: calc(100vh - 30px);
        display: flex;

        &__content {
          overflow: auto;
        }
      }
    }

    &__iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;

      &--hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    .sign-in-form {
      &__fields {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    & {
      .title {
        text-align: center;
        padding: 0 20px;
      }

      .sign-in-form {
        &__fields {
          padding: 0 20px;
          width: 100%;
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & {
      overflow: inherit;
      display: block;
      flex: none;
      margin: 0 auto;
    }
  }
}
