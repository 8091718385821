.modal-action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #eeeeee;
  padding: 20px 60px;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767.98px) {
    & {
      flex-direction: column;
      padding: 20px 20%;
    }

    & > * {
      width: 100%;
      margin: 0 0 15px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    & {
      padding: 20px;
    }
  }
}
