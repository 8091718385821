.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;

  &__title {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px * 2;
    margin-bottom: 0;
    text-align: left;

    &--center {
      text-align: center;
      padding-left: 60px * 2;
    }
  }

  .close-button {
    position: absolute;
    right: 60px;
  }

  @media screen and (max-width: 991.98px) {
    padding: 30px 30px 0 30px;

    .close-button {
      &__icon {
        height: 30px;
        width: 30px;

        &:after,
        &:before {
          width: 30px;
          bottom: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    padding: 30px 20px 0 20px;

    .close-button {
      right: 15px;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & {
      position: relative;

      .close-button {
        position: inherit;
      }
    }
  }
}
