.dialog {
  div#{&}__container {
    flex: 1;
  }

  &--center {
    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__paperWidthMd {
    min-width: 960px;
  }

  &__paperWidthSm {
    min-width: 600px;
  }

  &__paperWidthXs {
    min-width: 360px;
  }

  @media screen and (max-width: 960px) {
    .dialog__paperWidthMd {
      min-width: auto;
    }
  }

  @media screen and (max-width: 600px) {
    &__paperWidthMd {
      min-width: auto;
    }
  }

  @media screen and (max-width: 575.98px) {
    div#{&}__container {
      margin: 0 10px;
      max-height: calc(100% - 20px);
    }
  }

  @media screen and (max-width: 360px) {
    &__paperWidthMd {
      min-width: auto;
    }
  }
}
