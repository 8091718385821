@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700);
.dialog div.dialog__container{flex:1 1}.dialog--center>*{display:flex;justify-content:center;align-items:center}.dialog__paperWidthMd{min-width:960px}.dialog__paperWidthSm{min-width:600px}.dialog__paperWidthXs{min-width:360px}@media screen and (max-width: 960px){.dialog .dialog__paperWidthMd{min-width:auto}}@media screen and (max-width: 600px){.dialog__paperWidthMd{min-width:auto}}@media screen and (max-width: 575.98px){.dialog div.dialog__container{margin:0 10px;max-height:calc(100% - 20px)}}@media screen and (max-width: 360px){.dialog__paperWidthMd{min-width:auto}}

.modal-header{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding-top:60px}.modal-header__title{width:100%;padding-left:60px;padding-right:120px;margin-bottom:0;text-align:left}.modal-header__title--center{text-align:center;padding-left:120px}.modal-header .close-button{position:absolute;right:60px}@media screen and (max-width: 991.98px){.modal-header{padding:30px 30px 0 30px}.modal-header .close-button__icon{height:30px;width:30px}.modal-header .close-button__icon:after,.modal-header .close-button__icon:before{width:30px;bottom:15px}}@media screen and (max-width: 575.98px){.modal-header{padding:30px 20px 0 20px}.modal-header .close-button{right:15px}}@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){.modal-header{position:relative}.modal-header .close-button{position:inherit}}

.title{margin-bottom:10px;color:#404040}.title--heading-1{font:300 35px 'Open Sans', sans-serif}.title--heading-1b{font:600 35px 'Open Sans', sans-serif}.title--heading-2{font:400 20px 'Open Sans', sans-serif}.title--heading-3{font:600 18px 'Open Sans', sans-serif}.title--heading-4{font:600 16px 'Open Sans', sans-serif}.title--light{color:#ffffff}.title--bold{font-weight:700}@media screen and (max-width: 991.98px){.title--heading-1{font-size:26px}}@media screen and (max-width: 767.98px){.title--heading-1{font-size:20px}}

.modal-action-bar{display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;box-sizing:border-box;background-color:#eeeeee;padding:20px 60px}.modal-action-bar>*{margin-right:20px}.modal-action-bar>*:last-child{margin-right:0}@media screen and (max-width: 767.98px){.modal-action-bar{flex-direction:column;padding:20px 20%}.modal-action-bar>*{width:100%;margin:0 0 15px 0}.modal-action-bar>*:last-child{margin-bottom:0}}@media screen and (max-width: 575.98px){.modal-action-bar{padding:20px}}

.sign-up-page{display:flex;flex-direction:column;width:100%;flex:1 1}.sign-up-page .modal-header{padding-bottom:20px}.sign-up-page__container{min-height:calc(100% - 30px)}.sign-up-page__iframe{position:absolute;border:0;height:100%;width:100%;z-index:1}.sign-up-page__iframe--hidden{visibility:hidden;opacity:0}.sign-up-page__content{width:100%;position:relative;font-size:0;display:flex;flex:1 1}.sign-up-page__absolute-section{position:absolute;top:0;right:0;bottom:0;left:0;max-height:100%;overflow:auto;z-index:2}.sign-up-page__absolute-section-content{width:100%;padding:0 60px 60px 60px;font-size:16px}.sign-up-page__absolute-section-content>*{margin-bottom:20px}@media screen and (max-width: 991.98px){.sign-up-page .sign-in-form__fields{width:80%}.sign-up-page__container{min-height:calc(100% - 30px)}.sign-up-page__iframe{position:absolute;top:0;right:0;bottom:0;left:0;height:100%}.sign-up-page__iframe--hidden{visibility:hidden;opacity:0}}@media screen and (max-width: 767.98px){.sign-up-page__container{min-height:calc(100% - 20px)}.sign-up-page .modal-content{margin:15px;width:calc(100% - 30px);height:calc(100vh - 30px);display:flex}.sign-up-page .modal-content__content{overflow:auto}.sign-up-page__iframe{position:absolute;top:0;right:0;bottom:0;left:0;height:100%}.sign-up-page__iframe--hidden{visibility:hidden;opacity:0}.sign-up-page .sign-in-form__fields{width:90%}}@media screen and (max-width: 575.98px){.sign-up-page .title{text-align:center;padding:0 20px}.sign-up-page .sign-in-form__fields{padding:0 20px;width:100%}}@media all and (-ms-high-contrast: none), (-ms-high-contrast: active){.sign-up-page{overflow:inherit;display:block;flex:none;margin:0 auto}}

.forgot-password-page{display:flex;flex-direction:column;width:100%}.forgot-password-page iframe{border:0}@media screen and (max-width: 991.98px){.forgot-password-page .sign-in-form__fields{width:80%}}@media screen and (max-width: 767.98px){.forgot-password-page .sign-in-form__fields{width:90%}}@media screen and (max-width: 575.98px){.forgot-password-page .title{text-align:center;margin:20px 0;padding:0 20px}.forgot-password-page .sign-in-form__fields{padding:0 20px;width:100%}}

