@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

.title {
  margin-bottom: 10px;
  color: #404040;

  &--heading-1 {
    font: 300 35px 'Open Sans', sans-serif;
  }

  &--heading-1b {
    font: 600 35px 'Open Sans', sans-serif;
  }

  &--heading-2 {
    font: 400 20px 'Open Sans', sans-serif;
  }

  &--heading-3 {
    font: 600 18px 'Open Sans', sans-serif;
  }

  &--heading-4 {
    font: 600 16px 'Open Sans', sans-serif;
  }

  &--light {
    color: #ffffff;
  }
  &--bold {
    font-weight: 700;
  }

  @media screen and (max-width: 991.98px) {
    &--heading-1 {
      font-size: 26px;
    }
  }

  @media screen and (max-width: 767.98px) {
    &--heading-1 {
      font-size: 20px;
    }
  }
}
